<template>
  <div>
    <!-- Table Container Card -->
    <b-card>
      <div class="mb-2">
        <!-- Table Top -->
        <b-row>
          <b-col cols="12" md="12">
            <div class="d-flex align-items-center justify-content-end">
              <b-button variant="primary" class="d-flex justify-content-center align-items-center"
                href="/text-content/create" :disabled="isLoading" @click="isLoading = true">
                <span class="text-nowrap"> ساخت صفحه جدید</span>
                <b-spinner v-show="isLoading" class="position-absolute" small type="grow" />
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <!-- Table -->
      <LoadingComponent v-if="loading" />
      <div v-else>
        <div v-if="pages.length > 0">
          <div class="pt-1 pb-1 overflow" v-if="pages.length > 0">
            <vue-good-table :sort-options="{ enabled: false }" :columns="columns" :rows="pages" :rtl="false"
              class="w-100">
              <div slot="emptystate" class="w-100 text-center">
                موردی یافت نشد
              </div>
              <template slot="table-row" slot-scope="props" class="mx-auto">
                <!-- Column: Name -->
                <span v-if="props.column.field === 'id'" class="text-nowrap">
                  <span class="text-nowrap">{{ props.row.id }}</span>
                </span>
                <span v-if="props.column.field === 'title'" class="text-nowrap">
                  <span class="text-nowrap">{{ props.row.title }}</span>
                </span>
                <span v-if="props.column.field === 'slug'" class="text-nowrap">
                  <span class="text-nowrap">{{ props.row.slug }}</span>
                </span>
                <span v-if="props.column.field === 'url'" class="text-nowrap">
                  <span class="text-nowrap"><p class="mb-0" dir="ltr">{{'https://' + website_url +'/'+ props.row.url }}</p></span>
                  <br>
                  <b-button @click="doCopy(props.row.url)" variant="primary" :disabled="!props.row.url">کپی متن</b-button>
                </span>
                <span v-if="props.column.field === 'thumbnail'" class="text-nowrap">
                  <b-avatar :src="props.row.thumbnail" size="3rem" class="text-nowrap"></b-avatar>
                </span>
                <span v-if="props.column.field === 'status'" class="text-nowrap">
                  <span class="text-nowrap">{{ props.row.status }}</span>
                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'" style="width: 100px !important">
                  <span>
                    <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret :right="true">
                      <template v-slot:button-content>
                        <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
                      </template>
                      <b-dropdown-item @click="moveToEditPage(props.row.id)">
                        <feather-icon icon="Edit2Icon" class="mr-50" />
                        <span>ویرایش</span>
                      </b-dropdown-item>
                      <b-dropdown-item @click="deletePrompt(props.row.id)">
                        <feather-icon icon="TrashIcon" class="mr-50" />
                        <span>حذف</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </span>
                </span>
              </template>
            </vue-good-table>
          </div>
          <b-pagination v-model="currentPage" :total-rows="totalPages" :per-page="perPage" first-number last-number
            align="left" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0 p-0" dir="ltr">
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
        <div v-else>
          <h3 class="text-center">موردی یافت نشد</h3>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BCard,
  BSpinner,
  BRow,
  BCol,
  BButton,
  BPagination,
  BTable,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import axios from "axios";
import { VueGoodTable } from "vue-good-table";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import LoadingComponent from "../../components/LoadingComponent.vue";
import copy from 'copy-text-to-clipboard';

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BCard,
    BSpinner,
    BRow,
    BTable,
    BCol,
    BButton,
    BPagination,
    BDropdownItem,
    BDropdown,
    ToastificationContent,
    LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      loading: false,
      website_url: process.env.VUE_APP_BASE_URL.split('https://api.')[1],
      pages: [],
      pagesLength: 0,
      pageTray: [],
      totalPages: 0,
      perPage: 15,
      currentPage: 1,
      columns: [
        {
          label: "شناسه",
          field: "id",
        },
        {
          label: "عنوان",
          field: "title",
        },
        {
          label: "اسلاگ",
          field: "slug",
        },
        {
          label: "url",
          field: "url",
        },
        {
          label: "عملیات",
          field: "action",
        },
      ],
    };
  },
  methods: {
    getAllPages() {
      this.loading = true;
      axios
        .get("/api/v1/admin/pages", { params: { page: this.currentPage } })
        .then((response) => {
          this.pagesLength = response.data.data.length;
          this.totalPages = response.data.meta.total;
          this.perPage = response.data.meta.per_page;
          this.pages = [];
          if (this.pagesLength > 0) {
            this.pageTray = response.data.data;
            this.pageTray.forEach((item) => {
              this.pages.push({
                id: item.id,
                slug: item.slug,
                title: item.title,
                url: item.url,
              });
            });
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "خطا",
              icon: "XOctagonIcon",
              text: "مشکلی پیش آمده.",
              variant: "danger",
            },
          });
        });
    },
    deletePrompt(id) {
      this.$bvModal
        .msgBoxConfirm('آیا از حذف مطمئن هستید؟', {
          title: '',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'بله',
          cancelTitle: 'خیر',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value==true) {
            this.deletePage(id)
          }
        })
    },
    deletePage(id) {
      axios
        .post(`/api/v1/admin/pages/${id}/delete`)
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "صفحه حذف شد",
              icon: "CheckIcon",
              text: "صفحه با موفیقت حذف شد.",
              variant: "info",
            },
          });
          this.getAllPages();
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "خطا",
              icon: "XOctagonIcon",
              text: "مشکلی پیش آمده.",
              variant: "danger",
            },
          });
        });
    },
    moveToEditPage(id) {
      router.push(`/text-content/update/${id}`);
    },
    doCopy(text) {
      copy(`https://${process.env.VUE_APP_BASE_URL.split('https://api.')[1]}/` + text);

      this.$toast({
        component: ToastificationContent,
        props: {
          title: `آدرس مورد نظر کپی شد`,
          icon: "CheckIcon",
          text: `https://${process.env.VUE_APP_BASE_URL.split('https://api.')[1]}/${text}`,
          variant: "success",
        },
      });
    }
  },
  mounted() {
    this.getAllPages();
    this.$watch("currentPage", (newCurrentPage) => {
      this.getAllPages();
    });
  },
  unmounted() {
    this.$watch("currentPage");
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-select.scss";
.per-page-selector {
  width: 90px;
}
</style>

